import React from 'react'

function Header() {
    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark shadow">
            <div className="container-fluid">
                <a className="navbar-brand fs-2 ms-2" href="/">Deflix</a>
                <div className="container-fluid d-none d-sm-block"></div>
                <button className="btn btn-danger ms-4">Connect</button>
            </div>
        </nav>
    )
}

export default Header
