import './App.css';
import { Carousel } from './components/Carousel';
import Exchange from './components/Exchange';
import Header from './components/Header';

function App() {
  return (
    <>
      <Header />
      <Exchange />
      <Carousel title="Favorites" />
      <Carousel title="Vaults" />
      <Carousel title="Infos" />
      <div>&nbsp;</div>
    </>
  );
}

export default App;
