import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import './Carousel.css'

const options = {
    loop: true,
    margin: 10,
    nav: false,
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
            dots: false
        },
        600: {
            items: 3
        },
        1000: {
            items: 5
        }
    }
}

export class Carousel extends Component<{ title: string }, {}> {
    render() {
        return (
            <>
                <hr className="bg-black mt-5" />
                <h2 className="h1 text-white text-center mb-3">{this.props.title}</h2>
                <div className='container-fluid'>
                    <OwlCarousel className="owl-theme" {...options}>
                        <div className="card">1</div>
                        <div className="card">2</div>
                        <div className="card">3</div>
                        <div className="card">4</div>
                        <div className="card">5</div>
                        <div className="card">6</div>
                        <div className="card">7</div>
                        <div className="card">8</div>
                        <div className="card">9</div>
                        <div className="card">10</div>
                    </OwlCarousel>
                </div>

            </>
        )
    }
}